<template>
  <div v-if="page_loading">
    <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">เปิดแถบใหม่</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ค้นหา -->
    <v-card elevation="1" class="v-card-margin-bottom-30">
      <v-card-title>
        <v-icon color="blue" size="24">fa-sliders-h</v-icon>
        <span class="ml-2">ค้นหาข้อมูล</span>
      </v-card-title>
      <v-divider/>
      <v-card-text>

        <v-row>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" md="2"/>
              <v-col cols="12" md="2" class="pt-1 pb-2">
                วันที่ :
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <v-menu
                    ref="search_menu_date_from"
                    v-model="search_menu_date_from"
                    :close-on-content-click="false"
                    :return-value.sync="search_date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                    <v-text-field
                        :value="computed_search_date_from"
                        @change="value => computed_search_date_from = value"
                        autocomplete="off"
                        label="เริ่ม"
                        outlined
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker v-model="search_date" @input="search_menu_date_from = false, $refs.search_menu_date_from.save(search_date)" no-title   class="my-0">
                    </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn :loading="loading_btn" color="primary" class="pa-2 mt-1" @click="loaddata(), get_stock_diff()">
              <v-icon size="14" class="pr-1">fa-solid fa-magnifying-glass</v-icon>
              ค้นหา
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-row class="ma-0">
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" >
                <v-card-title>
                    จำนวนร้านค้า
                </v-card-title>
                <v-divider/>
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <!-- <h3 class="pb-4" >จำนวนร้านค้า</h3> -->
                                <h1 v-if="Data_general.totalShop!=null">
                                    {{(Data_general.totalShop) }}
                                </h1>
                                <h2 v-else>{{0 }}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon color="#845EC2" x-large >fa-store</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" >
                <v-card-title>
                    ออเดอร์ทั้งหมดวันนี้
                </v-card-title>
                <v-divider/>
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <!-- <h3 class="pb-4">ออเดอร์ทั้งหมดวันนี้ </h3> -->
                                <h1 class="pb-4" v-if="Data_general.totalOrderToday!=null">
                                    {{(Data_general.totalOrderToday)}}
                                </h1>
                                <h2 class="pb-4" v-else>{{0}}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon color="#2C73D2" x-large >fa-box-open</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" >
                <v-card-title>
                    ออเดอร์แพ็คทั้งหมดวันนี้
                </v-card-title>
                <v-divider/>
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <!-- <h3 class="pb-4" >ออเดอร์แพ็คทั้งหมดวันนี้</h3> -->
                                <h1 class="pb-4" v-if="Data_general.totalOrderPacked!=null">
                                    {{(Data_general.totalOrderPacked)}}
                                </h1>
                                <h2 v-else>{{0 }}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon color="#FF6F91" x-large >fa-box</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" >
                <v-card-title>
                    ออเดอร์ก่อนตัดรอบ
                </v-card-title>
                <v-divider/>
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <!-- <h3 class="pb-4" >ออเดอร์ก่อนตัดรอบ</h3> -->
                                <h1 class="pb-4" v-if="Data_general.orderBeforeCutOff!=null">
                                    {{(Data_general.orderBeforeCutOff)}}
                                </h1>
                                <h2 v-else>{{0 }}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon color="#0089BA" x-large >fa-clock</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center">
                <v-card-title>
                    ออเดอร์ก่อนตัดรอบที่แพ็คแล้ว
                </v-card-title>
                <v-divider/>
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <!-- <h3 class="pb-4" >ออเดอร์ก่อนตัดรอบที่แพ็คแล้ว</h3> -->
                                <h1 class="pb-4" v-if="Data_general.orderPackedBeforeCutOff!=null">
                                    {{(Data_general.orderPackedBeforeCutOff)}}
                                </h1>
                                <h2 v-else>{{0 }}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon color="#FFC75F" x-large >fa-box</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
            <v-card elevation="1" class="v-card-margin-bottom-30 align-center" >
                <v-card-title>
                    แพ็คสำเร็จ
                </v-card-title>
                <v-divider/>
                <v-card-text>
                        <v-row justify="center" align="center">
                            <v-col cols="8" class="py-0 px-0 pl-2">
                                <!-- <h3 class="pb-4" >แพ็คสำเร็จ</h3> -->
                                <h1 class="pb-4" v-if="Data_general.successPercent!=null">
                                    <span v-if="Data_general.successPercent >= 100" class="green--text">{{percent(Data_general.successPercent) }} </span>
                                    <span v-else-if="Data_general.successPercent <= 30" class="red--text">{{ percent(Data_general.successPercent) }}</span>
                                    <span v-else-if="Data_general.successPercent > 30 && Data_general.successPercent < 100  " class="orange--text">{{ percent(Data_general.successPercent) }}</span>
                                </h1>
                                <h2 v-else>{{0 }}</h2>
                            </v-col>
                            <v-col cols="4" class="py-0 px-0">
                                <v-avatar
                                    class="ma-3"
                                    size="100"
                                    tile
                                >
                                    <v-icon color="#FF9671" x-large >fa-boxes</v-icon>
                                </v-avatar>
                            </v-col>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">ภาพรวม</span>
            <!-- chart-line -->
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <GChart
                        type="BarChart"
                        :data="Data_general.bar_order_count"
                        :options="chartOptionsbar"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#02475E">
                จำนวนที่ต้องแพ็คก่อนตัดรอบและจำนวนที่แพ็คสำเร็จ
            </div>
        </v-card-text>
    </v-card>

    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3">สถิติรายชั่วโมง</span>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <GChart
                        type="LineChart"
                        :data="Data_general.graph_hour_count"
                        :options="chartOptionsline"
                    />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <div class="headline font-weight-thin" style="color:#02475E">
                จำนวนที่แพ็คต่อชั่วโมง
            </div>
        </v-card-text>
    </v-card>

    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3"> รายละเอียด</span>
            <v-spacer/>
            <vue-excel-xlsx
                v-if="Data_general.itemList.length > 0"
                :data="Data_general.itemList"
                :columns="excel_header_shop"
                :file-name="'filename'"
                :sheet-name="'รายละเอียด'"
                class="mr-2"
                >
                <v-btn :elevation="1" outlined color="success" class="pa-2" @click="exportexcel()">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                </v-btn>
            </vue-excel-xlsx>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12">

                  <!-- PC hidden-xs-only -->
                  <v-data-table
                      :headers="headers"
                      :items="Data_general.itemList"
                      :items-per-page="9999999999"
                      class="elevation-0 packhai-border-table hidden-xs-only"
                      style="min-height: 300px"
                      :mobile-breakpoint="0"
                      hide-default-footer
                  >
                  <template v-slot:item.successPercent="{ item }">
                      <span v-if="item.successPercent >= 100" class="green--text">{{ percent(item.successPercent) }}</span>
                      <span v-else-if="item.successPercent <= 30" class="red--text">{{ percent(item.successPercent) }}</span>
                      <span v-else-if="item.successPercent > 30 && item.successPercent < 100  " class="orange--text">{{ percent(item.successPercent) }}</span>
                  </template>
                  <template v-slot:item.statisticchart="{ item }">
                      <v-btn icon @click="statistic(item)"><v-icon color="#845EC2" >mdi-file-chart-outline </v-icon></v-btn>
                  </template>
                  </v-data-table>

                  <!-- Mobile hidden-sm-and-up -->
                  <v-data-iterator
                    :items="Data_general.itemList"
                    :items-per-page="9999999999"
                    hide-default-header
                    hide-default-footer
                    class="hidden-sm-and-up"
                  >
                    <template v-slot:default="props">
                      <v-row class="ma-0">
                        <v-col v-for="(item, index) in props.items" :key="index" cols="12" class="pa-1">
                          <v-card class="border102475E">
                            <v-row class="packhai-showproduct-3 pa-2">
                              <v-col cols="10" class="text-left px-0 pt-1 text-truncate">
                                <div>{{ item.shopName }}</div>
                              </v-col>
                              <v-col cols="2" class="text-right px-0 pt-1">
                                <span v-if="item.successPercent >= 100" class="green--text">{{ percent(item.successPercent) }}</span>
                                <span v-else-if="item.successPercent <= 30" class="red--text">{{ percent(item.successPercent) }}</span>
                                <span v-else-if="item.successPercent > 30 && item.successPercent < 100  " class="orange--text">{{ percent(item.successPercent) }}</span>
                              </v-col>
                            </v-row>
                            <v-divider class="border102475E"/>
                            <v-row class="packhai-showproduct-3 pa-3 pt-4">
                              <v-col cols="6" class="text-center px-0">
                                <div><span class="fn-18 pa-1"> {{ formatMoney(item.orderBeforeCutOff) }} </span></div>
                                <br/>
                                <div> ออเดอร์ก่อนตัดรอบ </div>
                              </v-col>
                              <v-col cols="6" class="text-center px-0">
                                <div><span class="fn-18 pa-1"> {{ formatMoney(item.orderPackedBeforeCutOff) }} </span></div>
                                <br/>
                                <div> ออเดอร์ก่อนตัดรอบที่แพ็คแล้ว </div>
                              </v-col>
                            </v-row>
                            <v-row class="packhai-showproduct-3 pa-3">
                              <v-col cols="6" class="text-center px-0">
                                <div><span class="fn-18 pa-1"> {{ formatMoney(item.totalOrderToday) }} </span></div>
                                <br/>
                                <div> ออเดอร์ทั้งหมดวันนี้ </div>
                              </v-col>
                              <v-col cols="6" class="text-center px-0">
                                <div><span class="fn-18 pa-1"> {{ formatMoney(item.totalOrderPacked) }} </span></div>
                                <br/>
                                <div> แพ็คแล้วทั้งหมด </div>
                              </v-col>
                            </v-row>
                            <v-row class="packhai-showproduct-3 pa-3 pt-0">
                              <v-col cols="12" class="text-right px-0">
                                <v-btn icon @click="statistic(item)"><v-icon color="#845EC2" >mdi-file-chart-outline </v-icon></v-btn>
                              </v-col>
                            </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </template>
                  </v-data-iterator>



                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <v-card elevation="1" class="v-card-margin-bottom-30 text-center">
        <v-card-title>
            <span class="mr-3"> รายละเอียดการแพ็ครายชั่วโมง</span>
            <v-spacer/>
            <vue-excel-xlsx
                v-if="Data_general.hourItemList.length > 0"
                :data="Data_general.hourItemList"
                :columns="excel_header_hour"
                :file-name="'filenamehour'"
                :sheet-name="'รายละเอียดการแพ็ครายชั่วโมง'"
                class="mr-2"
                >
                <v-btn :elevation="1" outlined color="success" class="pa-2" @click="exportexcelhour()">
                  <v-icon size="16" class="pr-1">fa-file-excel</v-icon>
                  <span class="fn-12"> Export Excel </span>
                </v-btn>
            </vue-excel-xlsx>
        </v-card-title>
        <v-divider/>
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-data-table
                        :headers="headers_hour"
                        :items="Data_general.hourItemList"
                        :items-per-page="100"
                        class="elevation-0 packhai-border-table"
                        style="min-height: 300px"
                        :mobile-breakpoint="0"
                        hide-default-footer
                    >
                        <template v-slot:item.h0="{ item }">
                            <span v-if="item.h0 != 0 " >{{ (item.h0) }}</span>
                        </template>
                        <template v-slot:item.h1="{ item }">
                            <span v-if="item.h1 != 0 " >{{ (item.h1) }}</span>
                        </template>
                        <template v-slot:item.h2="{ item }">
                            <span v-if="item.h2 != 0 " >{{ (item.h2) }}</span>
                        </template>
                        <template v-slot:item.h3="{ item }">
                            <span v-if="item.h3 != 0 " >{{ (item.h3) }}</span>
                        </template>
                        <template v-slot:item.h4="{ item }">
                            <span v-if="item.h4 != 0 " >{{ (item.h4) }}</span>
                        </template>
                        <template v-slot:item.h5="{ item }">
                            <span v-if="item.h5 != 0 " >{{ (item.h5) }}</span>
                        </template>
                        <template v-slot:item.h6="{ item }">
                            <span v-if="item.h6 != 0 " >{{ (item.h6) }}</span>
                        </template>
                        <template v-slot:item.h7="{ item }">
                            <span v-if="item.h7 != 0 " >{{ (item.h7) }}</span>
                        </template>
                        <template v-slot:item.h8="{ item }">
                            <span v-if="item.h8 != 0 " >{{ (item.h8) }}</span>
                        </template>
                        <template v-slot:item.h9="{ item }">
                            <span v-if="item.h9 != 0 " >{{ (item.h9) }}</span>
                        </template>
                        <template v-slot:item.h10="{ item }">
                            <span v-if="item.h10 != 0 " >{{ (item.h10) }}</span>
                        </template>
                        <template v-slot:item.h11="{ item }">
                            <span v-if="item.h11 != 0 " >{{ (item.h11) }}</span>
                        </template>
                        <template v-slot:item.h12="{ item }">
                            <span v-if="item.h12 != 0 " >{{ (item.h12) }}</span>
                        </template>
                        <template v-slot:item.h13="{ item }">
                            <span v-if="item.h13 != 0 " >{{ (item.h13) }}</span>
                        </template>
                        <template v-slot:item.h14="{ item }">
                            <span v-if="item.h14 != 0 " >{{ (item.h14) }}</span>
                        </template>
                        <template v-slot:item.h15="{ item }">
                            <span v-if="item.h15 != 0 " >{{ (item.h15) }}</span>
                        </template>
                        <template v-slot:item.h16="{ item }">
                            <span v-if="item.h16 != 0 " >{{ (item.h16) }}</span>
                        </template>
                        <template v-slot:item.h17="{ item }">
                            <span v-if="item.h17 != 0 " >{{ (item.h17) }}</span>
                        </template>
                        <template v-slot:item.h18="{ item }">
                            <span v-if="item.h18 != 0 " >{{ (item.h18) }}</span>
                        </template>
                        <template v-slot:item.h19="{ item }">
                            <span v-if="item.h19 != 0 " >{{ (item.h19) }}</span>
                        </template>
                        <template v-slot:item.h20="{ item }">
                            <span v-if="item.h20 != 0 " >{{ (item.h20) }}</span>
                        </template>
                        <template v-slot:item.h21="{ item }">
                            <span v-if="item.h21 != 0 " >{{ (item.h21) }}</span>
                        </template>
                        <template v-slot:item.h22="{ item }">
                            <span v-if="item.h22 != 0 " >{{ (item.h22) }}</span>
                        </template>
                        <template v-slot:item.h23="{ item }">
                            <span v-if="item.h23 != 0 " >{{ (item.h23) }}</span>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>

    <v-card elevation="1" class="v-card-margin-bottom-30 text-center" >
        <v-card-title>
            <span class="mr-3"> รายละเอียดตรวจสอบสต๊อกสินค้า</span>
            <v-spacer/>
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <div class="overflow-y-auto" style="max-height: 800px">
            <v-data-table
              :headers="stock_diff_headers"
              :items="stock_diff_list"
              hide-default-footer :items-per-page="10000"
              item-key="ID"
              class="elevation-0 packhai-border-table"
              >
              <template v-slot:item.photoLink="{ item }">
                <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                  <img :src="item.photoLink" class="picsize mt-1">
                </a>
                <a v-else>
                   <img src="https://packhaistorage.s3.ap-southeast-1.amazonaws.com/image/WhiteImage.JPG" class="picsize mt-1">
                </a>
              </template>
              <template v-slot:item.stockShopName="{ item }">
                <a  @click="gotoStock_InFo(item.stockShopID)">
                  <v-row>
                     <v-col cols="12" align="left" class="">
                            <span class="fn-13" style="font-weight: bold">{{item.stockShopName}}</span>
                     </v-col>
                  </v-row>
                </a>
              </template>
              <template v-slot:item.lastQuantity="{ item }">
                {{formatMoney(item.lastQuantity)}}
              </template>
              <template v-slot:item.totalOrderQuantity="{ item }">
                {{formatMoney(item.totalOrderQuantity)}}
              </template>
              <template v-slot:item.totalQuantity="{ item }">
                {{formatMoney(item.totalQuantity)}}
              </template>
              <template v-slot:item.diffQuantity="{ item }">
                {{formatMoney(item.diffQuantity)}}
              </template>
            </v-data-table>
          </div>
        </v-card-text>
    </v-card>

    <v-dialog v-model="dialog_shophour_count" scrollable :fullscreen="isFullscreen"  max-width="60%" persistent>
        <v-card elevation="1" class="ma-0 text-center">
            <v-card-title>
                <span>สถิติการแพ็ค</span>
                <v-spacer/>
                <v-btn icon @click="dialog_shophour_count=false"><v-icon color="danger">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-divider/>
            <v-card-text class="overflow-y-auto">
                <GChart
                    type="LineChart"
                    :data="shop_graph_hour_count"
                    :options="chartOptionslineshop"
                />
            </v-card-text>
            <v-card-text>
                <div class="headline font-weight-thin" style="color:#02475E">
                    จำนวนที่แพ็คต่อชั่วโมงของร้านค้า {{name_shop_hour_count}}
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

  </div>
</template>

<script>
  import axios from 'axios'
  import { header_token } from '@/website/token'
  import Loading from '@/website/components/Loading'
  import { GChart } from 'vue-google-charts'
  import { reportService_dotnet, stockService_dotnet } from '@/website/global'
  import { format_price, formatMoney } from '@/website/global_function'
  export default {
    components: {
      Loading,
      GChart
    },
    data:() =>({
      page_loading: true,
      loading: false,
      loading_btn:false,
      isFullscreen : false,

      search_menu_date_from: false,
      search_date: null,

      branchID:null,
      tab:null,
      selectDate:[{"text":"ตามวันที่","value":1},{"text":"ตามเดือน","value":2}],
      Transactionincome:1,
      Transactionorder:1,
      Transaction:1,
      ordercompare:1,
      Incomecompare:1,

      Data_general:null,
      chartOptionsbar:{
          colors:['#FF9800','#00C853'],
          height: 400,
      },
      chartOptionsline:{colors:['#FC5404','#F98404']},
      chartOptionslineshop:{colors:['#009EFA','#4FFBDF']},
      Data_chart:null,
      headers:[
              { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName'},
              { text: 'เวลาตัดรอบ', align: 'right', sortable: false, value: 'cutOffTime'},
              { text: 'จำนวนออเดอร์', align: 'right', sortable: false, value: 'totalOrderToday'},
              { text: 'แพ็คแล้วทั้งหมด', align: 'right', sortable: false, value: 'totalOrderPacked'},
              { text: 'ออเดอร์ก่อนตัดรอบ', align: 'right', sortable: false, value: 'orderBeforeCutOff'},
              { text: 'ออเดอร์ก่อนตัดรอบที่แพ็คแล้ว', align: 'right', sortable: false, value: 'orderPackedBeforeCutOff'},
              { text: 'แพ็คสำเร็จ', align: 'right', sortable: false, value: 'successPercent'},
              { text: 'สถิติการแพ็ค', align: 'center', sortable: false, value: 'statisticchart'},
              ],
      headers_hour:[
                      { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName'},
                      { text: '00:00 - 00:59', align: 'center', sortable: false, value: 'h0'},
                      { text: '01:00 - 01:59', align: 'center', sortable: false, value: 'h1'},
                      { text: '02:00 - 02:59', align: 'center', sortable: false, value: 'h2'},
                      { text: '03:00 - 03:59', align: 'center', sortable: false, value: 'h3'},
                      { text: '04:00 - 04:59', align: 'center', sortable: false, value: 'h4'},
                      { text: '05:00 - 05:59', align: 'center', sortable: false, value: 'h5'},
                      { text: '06:00 - 06:59', align: 'center', sortable: false, value: 'h6'},
                      { text: '07:00 - 07:59', align: 'center', sortable: false, value: 'h7'},
                      { text: '08:00 - 08:59', align: 'center', sortable: false, value: 'h8'},
                      { text: '09:00 - 09:59', align: 'center', sortable: false, value: 'h9'},
                      { text: '10:00 - 10:59', align: 'center', sortable: false, value: 'h10'},
                      { text: '11:00 - 11:59', align: 'center', sortable: false, value: 'h11'},
                      { text: '12:00 - 12:59', align: 'center', sortable: false, value: 'h12'},
                      { text: '13:00 - 13:59', align: 'center', sortable: false, value: 'h13'},
                      { text: '14:00 - 14:59', align: 'center', sortable: false, value: 'h14'},
                      { text: '15:00 - 15:59', align: 'center', sortable: false, value: 'h15'},
                      { text: '16:00 - 16:59', align: 'center', sortable: false, value: 'h16'},
                      { text: '17:00 - 17:59', align: 'center', sortable: false, value: 'h17'},
                      { text: '18:00 - 18:59', align: 'center', sortable: false, value: 'h18'},
                      { text: '19:00 - 19:59', align: 'center', sortable: false, value: 'h19'},
                      { text: '20:00 - 20:59', align: 'center', sortable: false, value: 'h20'},
                      { text: '21:00 - 21:59', align: 'center', sortable: false, value: 'h21'},
                      { text: '22:00 - 22:59', align: 'center', sortable: false, value: 'h22'},
                      { text: '23:00 - 23:59', align: 'center', sortable: false, value: 'h23'},
                   ],
      shop_graph_hour_count:null,
      name_shop_hour_count:null,
      dialog_shophour_count:false,

      // excel
      excel_header_shop:null,
      filename:null,
      excel_header_hour:null,
      filenamehour:null,

      // stock diff
      stock_diff_headers : [
        { text: 'ร้านค้า', align: 'left', sortable: false, value: 'shopName'},
        { text: 'รูป', align: 'left', sortable: false, value: 'photoLink'},
        { text: 'ชื่อสินค้า', align: 'left', sortable: false, value: 'stockShopName'},
        { text: 'SKU', align: 'left', sortable: false, value: 'sku'},
        { text: 'Barcode', align: 'left', sortable: false, value: 'barcode'},
        { text: 'จำนวนสินค้ายอนหลัง 1 วัน', align: 'right', sortable: false, value: 'lastQuantity'},
        { text: 'จำนวนสินค้าที่แพ็คทั้งหมดในวัน', align: 'right', sortable: false, value: 'totalOrderQuantity'},
        { text: 'จำนวนสินค้า ณ สิ้นวัน', align: 'right', sortable: false, value: 'totalQuantity'},
        { text: 'ผลต่างจำนวนสินค้า', align: 'right', sortable: false, value: 'diffQuantity'},
        // { text: 'วันที่', align: 'center', sortable: false, value: 'historyDate'},
      ],
      stock_diff_list: [],
      // ดูรูปภาพ
      ShowPhotoLink : null,
      ShowPhoto : false,
    }),
    async created() {
      var currentdate = new Date();
      this.search_date = currentdate.getFullYear()+"-"+(currentdate.getMonth()+1)+"-"+currentdate.getDate();
      this.branchID = localStorage.getItem('Branch_BranchID')
      await this.loaddata()
      await this.get_stock_diff()
      this.page_loading = false
    },
    computed: {

      computed_search_date_from() {
        return this.formatDates(this.search_date)
      },
    },
    methods:{
      format_price,
      allowedDates: val => val <= new Date().toISOString().substr(0, 10),
      formatDates(date) {
          if(date == null){
              return null
          } else {
          const [year, month, day] = date.split('-')
              return `${year}-${month}-${day}`
          }
      },
      async loaddata(){
        
    
      
          this.loading = true
          await axios.all([
              axios.post(reportService_dotnet+'BranchReport/packing-stat', {
                  "DateFrom":this.search_date,
                  "BranchId":this.branchID,
              },{headers:header_token}),
          ])
          .then(axios.spread((genral) => {
              if( genral.status == 200 ){
                  this.Data_general = genral.data
              }
          }));
          this.loading = false
      },
      statistic(item){
          this.shop_graph_hour_count = item.graph_hour_count
          this.name_shop_hour_count = item.shopName
          this.dialog_shophour_count = true
      },
      exportexcel(){
          this.filename = "รายละเอียด"+this.search_date
          this.excel_header_shop = [
                                  { label: 'ร้านค้า', field: "shopName", width: '25' },
                                  { label: 'เวลาตัดรอบ', field: "cutOffTime", width: '10',  },
                                  { label: 'จำนวนออเดอร์', field: "totalOrderToday", width: '10',  },
                                  { label: 'แพ็คแล้วทั้งหมด', field: "totalOrderPacked", width: '10',  },
                                  { label: 'ออเดอร์ก่อนตัดรอบ', field: "orderBeforeCutOff", width: '10',  },
                                  { label: 'ออเดอร์ก่อนตัดรอบที่แพ็คแล้ว', field: "orderPackedBeforeCutOff", width: '10',  },
                                  { label: 'แพ็คสำเร็จ', field: "successPercent", width: '10', dataFormat: this.percent, }
                                  ]
          return this.filename,this.excel_header_shop
      },
      exportexcelhour(){
          this.filenamehour = "รายละเอียดการแพ็ครายชั่วโมง"+this.search_date
          this.excel_header_hour = [
                                  { label: 'ร้านค้า', field: "shopName", width: '25' },
                                  { label: 'ชั่วโมง 1', field: "h1", width: '10',},
                                  { label: 'ชั่วโมง 2', field: "h2", width: '10',},
                                  { label: 'ชั่วโมง 3', field: "h3", width: '10',},
                                  { label: 'ชั่วโมง 4', field: "h4", width: '10',},
                                  { label: 'ชั่วโมง 5', field: "h5", width: '10',},
                                  { label: 'ชั่วโมง 6', field: "h6", width: '10',},
                                  { label: 'ชั่วโมง 7', field: "h7", width: '10',},
                                  { label: 'ชั่วโมง 8', field: "h8", width: '10',},
                                  { label: 'ชั่วโมง 9', field: "h9", width: '10',},
                                  { label: 'ชั่วโมง 10', field: "h10", width: '10',},
                                  { label: 'ชั่วโมง 11', field: "h11", width: '10',},
                                  { label: 'ชั่วโมง 12', field: "h12", width: '10',},
                                  { label: 'ชั่วโมง 13', field: "h13", width: '10',},
                                  { label: 'ชั่วโมง 14', field: "h14", width: '10',},
                                  { label: 'ชั่วโมง 15', field: "h15", width: '10',},
                                  { label: 'ชั่วโมง 16', field: "h16", width: '10',},
                                  { label: 'ชั่วโมง 17', field: "h17", width: '10',},
                                  { label: 'ชั่วโมง 18', field: "h18", width: '10',},
                                  { label: 'ชั่วโมง 19', field: "h19", width: '10',},
                                  { label: 'ชั่วโมง 20', field: "h20", width: '10',},
                                  { label: 'ชั่วโมง 21', field: "h21", width: '10',},
                                  { label: 'ชั่วโมง 22', field: "h22", width: '10',},
                                  { label: 'ชั่วโมง 23', field: "h23", width: '10',},
                                  { label: 'ชั่วโมง 24', field: "h24", width: '10',},
                                  ]
          return this.filename,this.excel_header_shop
      },
      percent(num){
        return num + " %" ;
      },
      async get_stock_diff () {
        let response = await axios.post(stockService_dotnet+'Stock/get-stock-shop-diff-daily-in-branch', {
            "branchID": this.branchID,
            "dateFrom": this.search_date + " 00:00:00",
            "dateTo": this.search_date + " 23:59:59",
        },{headers:header_token})

        this.stock_diff_list = response.data.dataList

      },
      gotoStock_InFo(stock_id){
        window.open("stock-info?id="+stock_id);
      },
      OpenPhoto(Photo){
        this.ShowPhotoLink = Photo
        this.ShowPhoto = true
      },
      Opentap(PhotoLink){
        window.open(PhotoLink);
      },
      formatMoney
    },
  }
</script>
